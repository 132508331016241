<template>
  <div>
    <component
      :is="computedComponentName"
      v-model="shouldShowModal"
      :close-on-content-click="false"
      :min-width="computedComponentName === 'v-menu' ? 100 : null"
    >
      <template v-slot:activator="{ on, attrs }">
        <div
          class="d-flex align-center justify-between filter-title"
          :class="{ 'font-weight-bold': isFilterApplied }"
          v-on="on"
          v-bind="attrs"
        >
          <div class="d-flex align-center flex-grow-1">
            <v-icon left>
              thumb_up
            </v-icon>
            Likes Growth
          </div>
          <v-icon>
            expand_more
          </v-icon>
        </div>
      </template>
      <v-card>
        <v-card-title class="d-md-none">
          Trending
        </v-card-title>

        <v-card-text
          class="pt-md-3"
          :class="{ 'black--text': isThemeLight }"
        >
          <div class="mb-1 d-flex">
            <div class="d-flex align-center flex-grow-1">
              <v-icon
                :class="{ 'black--text': isThemeLight }"
                class="mr-1"
                left
              >
                thumb_up
              </v-icon>

              Likes Growth
            </div>

            <!-- <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  :color="isThemeLight ? 'black' : null"
                  v-bind="attrs"
                  v-on="on"
                >
                  info
                </v-icon>
              </template>

              <span>

              </span>
            </v-tooltip> -->
          </div>

          <div class="contain-select-width">
            <v-select
              v-model="form.interval"
              :items="intervalOptions"
              dense
              outlined
              hide-details
              return-object
              label="Interval"
              @change="submitForm"
            ></v-select>
          </div>

          <div
            v-if="form.interval"
            class="contain-select-width mt-3"
          >
            <v-select
              v-model="form.weight"
              :items="weightOptions"
              dense
              outlined
              hide-details
              return-object
              label="Percentage"
              @change="submitForm"
            ></v-select>
          </div>
        </v-card-text>
      </v-card>
    </component>
  </div>
</template>

<script>
// used throughout the code
const filterName = "growthLikes"
const moduleName = "influencerDiscovery"


const intervalOptions = []
for (let i = 1; i <= 6; i++) {
  intervalOptions.push({
    text: `${i} month${i > 1 ? 's' : ''}`,
    value: `i${i}month${i > 1 ? 's' : ''}`
  })
}

const weightOptions = []
// gte values
for (let i = 10; i <= 400; i += 10) {
  weightOptions.push({
    text: `>${i}%`,
    value: {
      operator: "gte",
      percentage: String(i / 100)
    }
  })
}
// lte values
for (let i = 100; i <= 500; i += 10) {
  weightOptions.push({
    text: `<${i}%`,
    value: {
      operator: "lte",
      percentage: String(i / 100)
    }
  })
}

const originalForm = () => ({
  interval: null,
  weight: weightOptions[0]
})

export default {
  name: "FilterGrowthLikes",

  data: () => ({
    form: originalForm(),

    shouldShowModal: false,

    intervalOptions,
    weightOptions
  }),

  computed: {
    isToDisabled() {
      return parseInt(this.form.from, 10) === maxValue
    },

    // show the filter as a dialog for mobile dialogs
    computedComponentName() {
      return this.isDesktopDevice ? "v-menu" : "v-dialog"
    },

    // Used to bold the filter name text
    isFilterApplied() {
      return Boolean(this.$store.getters[`${moduleName}/findFiltersByType`](filterName).length)
    },
  },

  validations: {
    form: {}
  },

  methods: {
    /**
     * Takes an item and generates the text based on kind and weight
     */
    computeFilterText() {
      return !this.form.interval ? '' : `Likes Growth: ${this.form.interval.text} ${this.form.weight.text}`
    },

    /**
     * Push the data from either of the inputs to the filters array in Vuex Store
     *
     * @param {String} kind | Either "audience" or "influencer"
     */
    async submitForm() {
      await this.$v.form.$touch()

      if (this.$v.form.$anyError) return

      // if there's some value, then update the filter
      this.$store.dispatch(`${moduleName}/replaceFilter`, {
        type: filterName,
        data: {
          color: "teal lighten-5",
          icon: "expand",
          iconColor: "teal",
          text: this.computeFilterText(),
          inputs: this.form
        }
      })
    },
  },

  mounted() {
    // subscribe to window event when the chip is closed
    window.addEventListener("removeFilter", (e) => {
      // if it's not the one we want, don't execute the code
      if (e.detail.module !== moduleName || e.detail.item.type !== filterName) return

      // now that it is the one we want to see, continue
      this.form = originalForm()
    })

    // subscribe to window event when the chip is closed
    window.addEventListener("addFilter", (e) => {
      // if it's not the one we want, don't execute the code
      if (e.detail.module !== moduleName || e.detail.item.type !== filterName) return

      // now that it is the one we want to see, continue
      this.form = e.detail.item.data.inputs
    })
  }
}
</script>
